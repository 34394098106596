import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {HobbiesDetailProps} from './type';
import { PageLayout } from '../PageLayout';
import { Container } from '../Container';
import { Root, TwoColumnRoot, Left, Right } from './style';
import { HobbiesDetailSide } from './HobbiesDetailSide';
import { Link } from '../Link';
import {PageOffset} from "../PageOffset";
import {Typography} from "../Typography";
import {NarrowContainer} from "../NarrowContainer";
import {MDXComponents} from "../Providers/MDXComponents";
import Box from "@mui/material/Box";
import {useHobbiesDetail} from "./hooks/useHobbiesDetail";
import {SkillsV2} from "../SkillsV2";
import {HOBBIES_LIST} from "../../pathTemplates";
import {RoundButton} from "../RoundButton";
import {ImageViewer} from "../ImageViewer/ImageViewer";

/**
 * This component is for wrapping up pages that lay in the content/hobbies/ folder.
 * See gatsby-node.js for details.
 */
export function HobbiesDetail(props: HobbiesDetailProps) {
    const { year, title, skills, mdx } = useHobbiesDetail(props);

    return (
        <PageLayout pageContext={mdx}>
            <ImageViewer>
                <Root>
                    <TwoColumnRoot container spacing={6}>
                        <Left item md={4} sm={12}>
                            <HobbiesDetailSide data={mdx} />
                        </Left>
                        <Right item md={8} sm={12}>
                            <Container>
                                <Typography
                                    variant="h1"
                                    component="h1"
                                    enableVerticalGutter
                                >
                                    {title}
                                </Typography>
                            </Container>
                            {
                                (year !== 0)
                                &&
                                <NarrowContainer>
                                    <Typography variant="h4" component="span">
                                        Year: {year}
                                    </Typography>
                                </NarrowContainer>
                            }
                            {
                                skills.length > 0
                                &&
                                <NarrowContainer>
                                    <SkillsV2 list={skills.map(skill => skill.code).join(",")} marginTop="1.5rem" />
                                </NarrowContainer>
                            }
                            <div>
                                <MDXProvider components={MDXComponents}>
                                    <MDXRenderer pageContext={mdx}>{mdx.body}</MDXRenderer>
                                </MDXProvider>
                                <NarrowContainer>
                                    <Box marginTop={8} display="flex" justifyContent="center" width="100%">
                                        <Link
                                            to={HOBBIES_LIST}
                                        >
                                            <RoundButton>
                                                Back to list
                                            </RoundButton>
                                        </Link>
                                    </Box>
                                </NarrowContainer>
                                <PageOffset />
                            </div>
                        </Right>
                    </TwoColumnRoot>
                </Root>
            </ImageViewer>
        </PageLayout>
    );
}

export const HobbiesDetailQuery = graphql`
    query HobbiesDetailQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                keywords
                description
                displayPageTitle
                published
                date
                updatedAt
                learned
                tutorials {
                    url
                    text
                }
                skills {
                    code
                    version
                }
                images {
                    isCover
                    galleryCode
                    author
                    source
                    sourceText
                    title
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 3000
                                quality: 80
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default HobbiesDetail;
