import {ImageGalleryV2Image, ImageGalleryV2Props} from "./type";
import {useMemo} from "react";

export const useImageGalleryV2 = ({title, images, code, showTitle = true}: ImageGalleryV2Props) => {
    const galleryImage = useMemo(() => {
        let result = (images as ImageGalleryV2Image[])?.filter(image => !image.isCover) ?? [];
        if (code !== undefined && images) {
            result = result.filter((image) => {
                const galleryCode = image.galleryCode ?? "default";
                return galleryCode === code;
            });
        }

        return result;
    }, [images, code]);

    return {
        title: title ?? "Images",
        images: galleryImage,
        getImageProps: (image: ImageGalleryV2Image) => {
            return {
                // @ts-expect-error foo
                image: image.image!.childImageSharp.gatsbyImageData,
                alt: "",
                className: "image-viewer-image",
            };
        },
        showTitle,
    };
};
