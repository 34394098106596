import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import {muiSpacing} from "@gannochenko/ui.styled-components";

export const Root = styled.article`
    height: 100%;
    position: relative;
`;

export const TwoColumnRoot = styled(Grid)`
	margin-bottom: 0;
	margin-top: 0;
    height: 100%;
    position: relative;
`;

export const Left = styled(Grid)`
	padding-top: 0 !important;
	padding-bottom: 0 !important;
    height: 100%;
	position: relative;
    ${({ theme }) => css`
        ${theme.breakpoints.down('sm')} {
            display: none;
        }
    `};
`;

export const Right = styled(Grid)`
    padding-top: 0 !important;
    padding-bottom: 0 !important;
`;
