import { logo } from './assets';

type Skill = {
    key: string;
    title: string;
    logo?: any;
    size?: string;
    width?: string;
    width2?: string;
    isRound?: boolean;
    fontSize?: boolean;
    offsetX?: boolean;
    link?: string;
};

const skillList: Skill[] = [
    {
        key: 'reactjs',
        logo: logo.reactjs,
        title: 'React',
        size: '4rem',
    },
    {
        key: 'typescript',
        logo: logo.typescript,
        title: 'TypeScript',
        size: '3.5rem',
        isRound: true,
        link: "https://www.typescriptlang.org/",
    },
    {
        key: 'css',
        logo: logo.css,
        title: 'CSS3',
        size: '4.5rem',
    },
    {
        key: 'javascript',
        logo: logo.js,
        title: 'JavaScript',
        size: '3.5rem',
        isRound: true,
    },
    {
        key: 'html',
        logo: logo.html,
        title: 'HTML5',
        size: '4.5rem',
    },
    {
        key: 'apollo',
        title: 'Apollo GraphQL',
        logo: logo.apollo,
    },
    {
        key: 'gatsbyjs',
        logo: logo.gatsbyjs,
        title: 'Gatsby',
        size: '3.5rem',
    },
    {
        key: 'mobx',
        logo: logo.mobx,
        title: 'MobX',
        size: '3.5rem',
    },
    {
        key: 'reactquery',
        logo: logo.reactquery,
        title: 'React query',
        size: '3.5rem',
        //width: '3rem',
    },
    {
        key: 'styledcomponents',
        logo: logo.styledcomponents,
        title: 'Styled Components',
        size: '4.0rem',
        fontSize: 'small',
    },
    {
        key: 'git',
        logo: logo.git,
        title: 'GIT',
        size: '4rem',
    },
    {
        key: 'vercel',
        logo: logo.vercel,
        title: 'Vercel',
        size: '2.5rem',
        width: '3.0rem',
        widthV2: '1.5rem',
    },
    {
        key: 'nodejs',
        logo: logo.nodejs,
        title: 'Node',
        size: '2.5rem',
        width: '4rem',
        widthV2: '2.1rem',
        link: 'https://nodejs.org/',
    },
    {
        key: 'docker',
        logo: logo.docker,
        title: 'Docker',
        size: '4.5rem',
    },
    {
        key: 'terraform',
        logo: logo.terraform,
        title: 'Terraform',
        size: '1.15rem',
        width: '4.8rem',
        widthV2: '5.5rem',
    },
    {
        key: 'kubernetes',
        logo: logo.kubernetes,
        title: 'Kubernetes',
        size: '3.5rem',
    },
    {
        key: 'serverless',
        logo: logo.serverless,
        title: 'Serverless',
        size: '3.5rem',
        isRound: true,
    },
    {
        key: 'aws',
        logo: logo.aws,
        title: 'AWS',
        size: '4.5rem',
    },
    {
        key: 'expressjs',
        logo: logo.expressjs,
        title: 'Express',
        size: '4rem',
    },
    {
        key: 'nestjs',
        logo: logo.nestjs,
        title: 'Nest',
        size: '3.5rem',
    },
    {
        key: 'mongodb',
        logo: logo.mongodb,
        title: 'MongoDB',
        size: '4.5rem',
        widthV2: '3rem',
    },
    {
        key: 'mysql',
        logo: logo.mysql,
        title: 'MySQL',
        size: '4.5rem',
        isRound: true,
        widthV2: '2.5rem',
    },
    {
        key: 'postgressql',
        logo: logo.postgressql,
        title: 'PostgreSQL',
        size: '4.0rem',
    },
    {
        key: 'redis',
        logo: logo.redis,
        title: 'Redis',
        size: '4rem',
    },
    {
        key: 'typeorm',
        logo: logo.typeorm,
        title: 'TypeORM',
        size: '2rem',
        width: '4rem',
        widthV2: '2.5rem',
    },
    {
        key: 'linux',
        logo: logo.linux,
        title: 'Linux',
        size: '4rem',
        offsetX: '0.7rem',
    },
    {
        key: 'sass',
        logo: logo.sass,
        title: 'SASS',
        size: '2.5rem',
        width: '4rem',
    },
    {
        key: 'less',
        logo: logo.less,
        title: 'LESS',
        size: '2.0rem',
        width: '4.5rem',
        widthV2: '2.5rem',
    },
    {
        key: 'redux',
        logo: logo.redux,
        title: 'Redux',
        size: '4rem',
    },
    {
        key: 'saga',
        logo: logo.saga,
        title: 'Redux Saga',
        size: '2.2rem',
        width: '3.6rem',
        widthV2: '2.0rem',
    },
    {
        key: 'vagrant',
        logo: logo.vagrant,
        title: 'Vagrant',
        size: '1.15rem',
        width: '4.4rem',
        widthV2: '5rem',
    },
    {
        key: 'auth0',
        logo: logo.auth0,
        title: 'Auth0',
        size: '3rem',
        width: '2.7rem',
    },
    {
        key: 'netlify',
        logo: logo.netlify,
        title: 'Netlify',
        size: '3.5rem',
        width: '3.5rem',
    },
    {
        key: 'meteorjs',
        logo: logo.meteorjs,
        title: 'Meteor',
        size: '4rem',
    },
    {
        key: 'php',
        logo: logo.php,
        title: 'PHP',
        size: '3.5rem',
        isRound: true,
    },
    {
        key: 'angular',
        logo: logo.angularjs,
        title: 'Angular',
        size: '4rem',
    },
    {
        key: 'perl',
        logo: logo.perl,
        title: 'Perl',
        size: '2rem',
        width: '3rem',
        widthV2: '2.5rem',
    },
    {
        key: 'sharp',
        logo: logo.csharp,
        title: 'C#',
        size: '2.5rem',
        width: '3rem',
        widthV2: '1.4rem',
    },
    {
        key: 'python',
        logo: logo.python,
        title: 'Python',
        size: '1.2rem',
        width: '4rem',
        widthV2: '4rem',
    },
    {
        key: 'kotlin',
        logo: logo.kotlin,
        title: 'Kotlin',
        size: '2.5rem',
        //width: '3rem',
    },
    {
        key: '1cbitrix',
        logo: logo.bitrix,
        title: '1C Bitrix',
        size: '3.5rem',
    },
    {
        key: 'hg',
        logo: logo.hg,
        title: 'Mercurial',
        size: '3rem',
        width: '3.1rem',
    },
    {
        key: '3dsmax',
        logo: logo.autodesk,
        title: '3DS Max',
        size: '3rem',
        width: '3.1rem',
        link: "https://www.autodesk.com/products/3ds-max/overview",
    },
    {
        key: 'vray',
        logo: logo.vray,
        title: 'VRay',
        size: '5rem',
    },
    {
        key: 'jamstack',
        logo: logo.jamstack,
        title: 'Jamstack',
        size: '3rem',
    },
    {
        key: 'nextjs',
        logo: logo.nextjs,
        title: 'Nextjs',
        widthV2: '2.5rem',
    },
    {
        key: 'reactnative',
        logo: logo.reactjs,
        title: 'React Native',
    },
    {
        key: 'photoshop',
        logo: logo.photoshop,
        title: 'Adobe Photoshop',
    },
    {
        key: 'aftereffects',
        logo: logo.aftereffects,
        title: 'Adobe AfterEffects',
    },
    {
        key: 'bitrix24',
        logo: logo.bitrix24,
        title: 'Bitrix24',
        widthV2: '7rem',
    },
    {
        key: 'jest',
        logo: logo.jest,
        title: 'Jest',
    },
    {
        key: 'blender',
        logo: logo.blender,
        title: 'Blender',
        widthV2: '1.5rem',
        link: "https://www.blender.org/",
    },
    {
        key: 'poliigon',
        title: 'Poliigon',
        link: "https://www.poliigon.com/",
    },
    {
        key: 'polyheaven',
        title: 'Polyheaven',
        link: "https://polyhaven.com/a/rock_01",
    },
    {
        key: 'blenderkit',
        title: 'Blenderkit',
        link: "https://www.blenderkit.com/",
    },
    {
        key: 'vray',
        title: 'V-Ray',
        link: "https://www.chaos.com/vray",
    },
    {
        key: 'go',
        logo: logo.go,
        title: 'Go',
        widthV2: '3.5rem',
    },
    {
        key: 'googlecloud',
        logo: logo.googlecloud,
        title: 'Google Cloud',
        widthV2: '1.6rem',
    },
    {
        key: 'arduino',
        logo: logo.arduino,
        title: 'Arduino',
        widthV2: '1.8rem',
    },
    {
        key: 'cpp',
        logo: logo.cpp,
        title: 'C++',
        widthV2: '1.15rem',
    },
    {
        key: 'java',
        logo: logo.java,
        title: 'Java',
        widthV2: '1.2rem',
    },
    {
        key: 'raspberry',
        logo: logo.raspberry,
        title: 'Raspberry Pi',
        widthV2: '1.0rem',
    },
    {
        key: 'flutter',
        logo: logo.flutter,
        title: 'Flutter',
        widthV2: '1.0rem',
    },
    {
        key: 'dart',
        logo: logo.dart,
        title: 'Dart',
        widthV2: '1.2rem',
    },
    {
        key: 'unrealengine',
        logo: logo.unrealengine,
        title: 'Unreal Engine',
        // widthV2: '1rem',
        link: "https://www.unrealengine.com/"
    },
    {
        key: 'megascans',
        title: 'Quixel Megascans',
        link: "https://quixel.com/megascans"
    },
    {
        key: 'midjourney',
        title: 'Midjourney',
        link: "https://www.midjourney.com/home/"
    },
    {
        key: 'protobuf',
        title: 'Protobuf',
        link: "https://protobuf.dev/"
    },
    {
        key: 'gmax',
        title: 'Gmax',
        link: "https://en.wikipedia.org/wiki/Gmax"
    },
    // soft skills
    {
        key: 'tech_interview',
        title: 'Technical interview',
    },
];

export const skills: Record<
    string,
    Skill
> = {};

skillList.forEach((skill) => (skills[skill.key] = skill));
