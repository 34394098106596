import React, { FC } from 'react';
import { Avatar } from '../Avatar';
import {
    AuthorGreetingBlock,
    AuthorLeft,
    AuthorLinks,
    AuthorNameBlock,
    AuthorRight,
    AuthorRoot,
    Banner,
    BannerDove,
} from './style';
import { AuthorIntro, authorName } from '../../meta/author';
import { Social } from '../Social';
import { links } from '../../meta/links';
import { AuthorPropsType } from './type';
import { useAuthor } from './hooks/useAuthor';

export const Author: FC<AuthorPropsType> = (props) => {
    const {
        showSocialLinks,
        avatarContainerProps,
        nameBlockProps,
        greetingsBlockProps,
        socialLinksProps,
        showUkraineBanner,
    } = useAuthor(props);

    return (
        <AuthorRoot>
            <AuthorLeft {...avatarContainerProps}>
                <Avatar />
                {showUkraineBanner && (
                    <>
                        <Banner />
                        <BannerDove />
                    </>
                )}
            </AuthorLeft>
            <AuthorRight>
                <AuthorNameBlock {...nameBlockProps}>
                    {authorName}
                </AuthorNameBlock>
                <AuthorGreetingBlock {...greetingsBlockProps}>
                    <AuthorIntro />
                </AuthorGreetingBlock>
                {showSocialLinks && (
                    <AuthorLinks {...socialLinksProps}>
                        <Social type="github" src={links.github} />
                        <Social type="linkedin" src={links.linkedin} />
                        {/*<Social type="twitter" src={links.twitter} />*/}
                        <Social type="instagram" src={links.instagram} />
                        <Social type="youtube" src={links.youtube} />
                        <Social type="telegram" src={links.telegram} />
                        {/*<Social type="devto" src={links.devto} />*/}
                    </AuthorLinks>
                )}
            </AuthorRight>
        </AuthorRoot>
    );
};
