import {NarrowContainer} from "../NarrowContainer";
import {Typography} from "../Typography";
import {ItemList} from "../ItemList";
import React, {useMemo} from "react";
import {HobbiesEntry} from "../../type";

type ArtBottomSummaryProps = {
    data: HobbiesEntry;
};

export function ArtBottomSummary({data}: ArtBottomSummaryProps) {
    const tutorials = data?.tutorials ?? [];
    const learned = useMemo(() => {
        return data?.learned?.map(item => ({
            text: item,
        })) ?? [];
    }, [data.learned]);

    return (
        <>
            {
                learned.length > 0
                &&
                <>
                    <Typography
                        variant="h2"
                        component="h2"
                        enableVerticalGutter
                    >
                        Learned this time
                    </Typography>
                    <ItemList
                        items={learned}
                    />
                </>
            }
            {
                tutorials.length > 0
                &&
                <>
                    <Typography
                        variant="h2"
                        component="h2"
                        enableVerticalGutter
                    >
                        Tutorials used
                    </Typography>
                    <ItemList
                        markerEmoji="👉"
                        items={tutorials}
                    />
                </>
            }
        </>
    );
};
