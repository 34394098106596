import styled from "styled-components";
import {muiSpacing} from "@gannochenko/ui.styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

export const Root = styled.div`
  padding-bottom: ${muiSpacing(2)};
`;

export const ImageGalleryImageWrapper = styled("a")`
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  display: block;
  cursor: pointer;
  height: 18rem;
  margin: ${muiSpacing(5 / 2)};
`;

export const ImageGalleryImage = styled(GatsbyImage)`
  background-color: #c4c4c4;
  object-fit: cover;
  object-position: center;
  position: absolute !important;
  width: 100%;
  height: 100%;
`;
