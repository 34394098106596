import React from "react";
import Grid from "@material-ui/core/Grid";

import {Root, ImageGalleryImage, ImageGalleryImageWrapper} from "./style";
import {useImageGalleryV2} from "./useImageGalleryV2";
import {ImageGalleryV2Props} from "./type";
import {Typography} from "../Typography";

export function ImageGalleryV2(props: ImageGalleryV2Props) {
    const {
        title,
        images,
        getImageProps,
        showTitle,
    } = useImageGalleryV2(props);

    return (
        <Root>
            {
                showTitle
                &&
                <Typography
                    variant="h2"
                    component="h2"
                    enableVerticalGutter
                >
                    {title}
                </Typography>
            }
            <Grid container spacing={5}>
                {images.map((image, index) => {
                    return (
                        <Grid md={6} sm={6} xs={12} key={index} component="div">
                            <ImageGalleryImageWrapper>
                                <ImageGalleryImage {...getImageProps(image)} />
                            </ImageGalleryImageWrapper>
                        </Grid>
                    );
                })}
            </Grid>
        </Root>
    );
}
