import { useState } from 'react';

export const useFooter = () => {
    const [showAT, setShowAT] = useState(false);
    const [showArch, setShowArch] = useState(false);
    const [showDEV, setShowDEV] = useState(false);

    const [slug, setSlug] = useState("");

    let gALink = process.env.BLOG__GA_LINK;
    let deploymentLink = process.env.BLOG__DEPLOYMENT_LINK;
    
    return {
        showDEV,
        setShowDEV,
        showArch,
        setShowArch,
        showAT,
        setShowAT,
        showGALinkLink: !!gALink,
        gALink,
        showDeploymentLink: !!deploymentLink,
        deploymentLink,
        slug,
        setSlug,
    };
};
