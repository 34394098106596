import {HobbiesEntry} from "../../../type";
import {HobbiesDetailProps} from "../type";

export const useHobbiesDetail = ({data}: HobbiesDetailProps) => {
	const { mdx } = data ?? {};
	const frontmatter = mdx?.frontmatter as HobbiesEntry;

	let year = 0;
	const dateSplit = (frontmatter?.date ?? "").split("-");
	if (dateSplit.length === 3) {
		year = parseInt(dateSplit[0])
	}

	return {
		year,
		title: frontmatter?.title ?? "",
		skills: frontmatter?.skills ?? [],
		mdx,
		data: frontmatter,
	};
};
